<template>
  <div class="popup-add-excursion">
    <div class="popup-add-excursion__wrapper">
      <Column :class="$viewport.desktop ? 'grid-6' : ''">
        <p class="popup-add-excursion__title">{{ popupTitle }}</p>
        <p class="popup-add-excursion__description">Благодарим за Ваш интерес к проекту. Давайте
          открывать Томскую область вместе!</p>
        <label class="popup-add-fact__label"
               for="excursionTitle">Название маршрута</label>
        <input class="popup-add-fact__input"
               id="excursionTitle"
               type="text"
               v-model="excursionTitle">
        <div>
          <span class="popup-add-object__label">Тип маршрута</span>
          <SelectComponent
            id="excursionType"
            :array="subtypes"
            itemKey="name"
            v-on:change-index="changeIndex"
            :index="idSubtypes"/>
        </div>
        <label class="popup-add-object__label"
               for="excursionContacts">Контакты</label>
        <input class="popup-add-object__input"
               id="excursionContacts"
               type="text"
               v-model="excursionContacts">
        <Info text="Вы можете указать любую полезную контактную
              информацию (телефон, сайт, график работы, запись на экскурсии)."/>
        <label class="popup-add-fact__label"
               for="excursionCost">Стоимость, ₽</label>
        <input class="popup-add-fact__input"
               id="excursionCost"
               type="text"
               v-model="excursionCost">
        <label class="popup-add-fact__label"
               for="excursionDuration">Длительность</label>
        <input class="popup-add-fact__input"
               id="excursionDuration"
               type="text"
               v-model="excursionDuration">
        <label class="popup-add-fact__label"
               for="excursionDistance">Дистанция</label>
        <input class="popup-add-fact__input"
               id="excursionDistance"
               type="text"
               v-model="excursionDistance">
        <label class="popup-add-fact__label"
               for="levelId">Уровень</label>
        <div id="levelId"
             class="agencies-guides__list-filter-select select"
             :class="showLevel ? 'select-opened' : ''"
             v-on:click="toggleSelectLevel">
          <Icon class="select-icon"
                xlink="arrow-navigation"
                :rotate="showLevel ? 0 : 180"
                viewport="0 0 20 20"/>
          <div class="selected">
            {{levels.find(item => item.id === excursionLevel).name}}
          </div>
          <div v-if="showLevel"
               class="select-list">
            <div v-for="level in levels"
                 :key="level.id"
                 v-on:click="selectLevel(level.id)"
                 class="select-item">{{level.name}}
            </div>
          </div>
        </div>
        <Row align="center"
             justify="between">
          <label class="popup-add-object__label"
                 for="excursionDescription">Описание</label>
          <span class="popup-add-object__textarea-counter">
            Осталось {{textareaCount}} символов</span>
        </Row>
        <textarea class="popup-add-object__input"
                  id="excursionDescription"
                  v-model="excursionDescription"/>
        <Info text="В нескольких предложениях опишите ваш маршрут,
              чем он хорош и кому будет интересен. Перечислите объекты на маршруте и как до них
              можно добраться, если путь до них сложен и неочевиден."/>
        <div id="cityId"
             class="agencies-guides__list-filter-select select"
             :class="showCity ? 'select-opened' : ''"
             v-on:click="toggleSelectCity">
          <Icon class="select-icon"
                xlink="arrow-navigation"
                :rotate="showCity ? 0 : 180"
                viewport="0 0 20 20"/>
          <div class="selected">
            {{$cities.data.find(item => item.id === excursionCityId).name}}
          </div>
          <div v-if="showCity"
               class="select-list">
            <div v-for="city in $cities.data"
                 :key="city.id"
                 v-on:click="selectCity(city.id)"
                 class="select-item">{{city.name}}
            </div>
          </div>
        </div>
        <label class="popup-add-fact__label"
               for="excursionVideo">Ссылка на видео</label>
        <input class="popup-add-fact__input"
               id="excursionVideo"
               type="text"
               v-model="excursionVideo">
        <AddFile
          :params="{label: 'Фотографии'}"
          v-on:change-file="changeFile"
          v-on:change-preview="changePreview"
        />
        <label class="popup-add-fact__label"
               for="objectTypeId">План тура</label>
        <div id="objectTypeId"
             class="agencies-guides__list-filter-select select"
             :class="showPlace ? 'select-opened' : ''"
             v-on:click="toggleSelectPlace">
          <Icon class="select-icon"
                xlink="arrow-navigation"
                :rotate="showPlace ? 0 : 180"
                viewport="0 0 20 20"/>
          <div class="selected">Выберите место</div>
          <div v-if="showPlace"
               class="select-list">
            <div v-for="place in tripPlaces"
                 :key="place.id"
                 v-on:click="selectPlace(place.id)"
                 class="select-item">{{place.title}}
            </div>
          </div>
        </div>
        <Row v-if="excursionSelectedPlaces.length > 0"
             class="popup-add-excursion__previews-places"
             wrap>
          <div :key="preview.id"
               class="preview-place"
               v-for="(preview, index) in excursionSelectedPlaces">
            <img :src="preview.images[0]"
                 class="preview-place-image"/>
            <Icon class="preview-place-remove"
                  v-on:click.native="remove(preview.id)"
                  viewport="0 0 20 20"
                  xlink="close-filled"/>
            <div class="preview-place-number">{{index + 1}}</div>
            <div class="preview-place-title-holder">
              <span class="preview-place-title">{{title(preview.title)}}</span>
            </div>
          </div>
        </Row>
        <div>
          <Button color="green"
                  :disabled="disabled"
                  v-on:click.native="addExcursion">Добавить маршрут
          </Button>
        </div>
      </Column>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PopupAddTrip',
  data() {
    return {
      disabled: false,
      showCity: false,
      showLevel: false,
      showPlace: false,
      objectTypeId: null,
      textareaMaxCount: 700,
      excursionTitle: '',
      excursionContacts: '',
      excursionCost: '',
      excursionDuration: '',
      excursionDistance: '',
      excursionDescription: '',
      excursionVideo: '',
      excursionSelectedPlaces: [],
      excursionLevel: 0,
      excursionCityId: 2,
      excursionImages: [],
      idSubtypes: 1,
      levels: [{
        id: 0,
        name: 'Легкий',
      }, {
        id: 1,
        name: 'Средний',
      }, {
        id: 2,
        name: 'Сложный',
      }],
      popupTitle: 'Создание маршрута',
      tripPlaces: [],
    };
  },
  computed: {
    textareaCount() {
      return this.textareaMaxCount - this.excursionDescription.length;
    },
    subtypes() {
      return [{
        id: null,
        name: 'Выберите...',
      }, ...this.$tripSubTypes];
    },
  },
  created() {
    this.$store.dispatch('GET_ALL_PLACES_FROM_SERVER').then(() => {
      this.tripPlaces = this.$places.data;
    });
    this.$store.dispatch('GET_TRIP_SUB_TYPES_FROM_SERVER');

    if (this.$popup && this.$popup.additions && this.$popup.additions.id) {
      if (this.$popup.additions.title) this.excursionTitle = this.$popup.additions.title;
      if (this.$popup.additions.subtype_id) this.idSubtypes = this.$popup.additions.subtype_id;
      // eslint-disable-next-line max-len
      if (this.$popup.additions.description) this.excursionDescription = this.$popup.additions.description;
      if (this.$popup.additions.contacts) this.excursionContacts = this.$popup.additions.contacts;
      if (this.$popup.additions.cost) this.excursionCost = this.$popup.additions.cost;
      if (this.$popup.additions.duration) this.excursionDuration = this.$popup.additions.duration;
      if (this.$popup.additions.distance) this.excursionDistance = this.$popup.additions.distance;
      if (this.$popup.additions.video) this.excursionVideo = this.$popup.additions.video;
      // eslint-disable-next-line max-len
      if (this.$popup.additions.places) this.excursionSelectedPlaces = this.$popup.additions.places;
      if (this.$popup.additions.level) this.excursionLevel = this.$popup.additions.level;
      if (this.$popup.additions.city_id) this.excursionCityId = this.$popup.additions.city_id;
      if (this.$popup.additions.images) this.excursionImages = this.$popup.additions.images;
      this.popupTitle = 'Редактирование маршрута';
    }
  },
  methods: {
    changeFile(file) {
      return file;
    },
    changePreview(preview) {
      this.excursionImages = preview;
    },
    changeIndex(id) {
      this.idSubtypes = id;
    },
    addExcursion() {
      this.disabled = true;
      let excursion = '?';
      if (this.excursionLevel) excursion += `&level=${this.excursionLevel}`;
      if (this.excursionDistance) excursion += `&distance=${this.excursionDistance}`;
      if (this.excursionDuration) excursion += `&duration=${this.excursionDuration}`;
      if (this.excursionTitle) excursion += `&title=${this.excursionTitle}`;
      if (this.excursionDescription) excursion += `&description=${this.excursionDescription}`;
      excursion += '&type=route';
      if (this.excursionCityId) excursion += `&city_id=${this.excursionCityId}`;
      if (this.excursionCost) excursion += `&cost=${this.excursionCost}`;
      if (this.excursionContacts) excursion += `&contacts=${this.excursionContacts}`;
      if (this.excursionVideo) excursion += `&videos=${this.excursionVideo}`;
      if (this.idSubtypes) excursion += `&subtype_id=${this.idSubtypes}`;
      if (this.excursionImages.length > 0) excursion += `&images=${this.previewsToString()}`;
      const formData = new FormData();
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      this.excursionSelectedPlaces.forEach((item) => {
        formData.append('places[]', item.id);
      });
      if (this.$popup && this.$popup.additions && this.$popup.additions.id) {
        this.$putAddExcursion(excursion, formData, config, this.$popup.additions.id);
      } else {
        this.$postAddExcursion(excursion, formData, config);
      }
    },
    title(title) {
      return title.length > 20 ? `${title.slice(0, 20)}...` : title;
    },
    toggleSelectCity() {
      this.showCity = !this.showCity;
    },
    toggleSelectLevel() {
      this.showLevel = !this.showLevel;
    },
    toggleSelectPlace() {
      this.showPlace = !this.showPlace;
    },
    selectCity(id) {
      this.excursionCityId = id;
    },
    selectLevel(id) {
      this.excursionLevel = id;
    },
    selectPlace(id) {
      this.objectTypeId = id;
      this.excursionSelectedPlaces = [...this.excursionSelectedPlaces,
        this.tripPlaces.find(item => item.id === id)];
      this.reloadTripPlaces();
    },
    remove(id) {
      this.excursionSelectedPlaces = this.excursionSelectedPlaces.filter(item => item.id !== id);
      this.reloadTripPlaces();
    },
    reloadTripPlaces() {
      // eslint-disable-next-line max-len
      this.tripPlaces = this.$places.data.filter(item => !this.excursionSelectedPlaces.includes(item));
    },
    previewsToString() {
      let string = '';
      this.excursionImages.forEach((item, i, array) => {
        string += `${i === 0 ? '[' : ''}"${item}"${i !== array.length - 1 ? ',' : ']'}`;
      });
      return string;
    },
  },
};
</script>
